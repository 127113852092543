@font-face {
    font-family: 'Archer Book';
    src: url('Archer-Book.woff2') format('woff2'),
        url('Archer-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('Archer-Bold.woff2') format('woff2'),
        url('Archer-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer Book';
    src: url('Archer-BookItalic.woff2') format('woff2'),
        url('Archer-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('Archer-LightItalic.woff2') format('woff2'),
        url('Archer-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Archer Hairline';
    src: url('Archer-Hairline.woff2') format('woff2'),
        url('Archer-Hairline.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('Archer-Light.woff2') format('woff2'),
        url('Archer-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('Archer-Medium.woff2') format('woff2'),
        url('Archer-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer';
    src: url('Archer-Semibold.woff2') format('woff2'),
        url('Archer-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kaushan Script';
    src: url('KaushanScript-Regular.woff2') format('woff2'),
        url('KaushanScript-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archer Extra';
    src: url('Archer-ExtraLight.woff2') format('woff2'),
        url('Archer-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MikkelScript';
    src: url('MikkelScript-Bold.woff2') format('woff2'),
        url('MikkelScript-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mikkel Sans';
    src: url('MikkelSans-Bold.woff2') format('woff2'),
        url('MikkelSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lemon Tea';
    font-style: normal;
    font-weight: normal;
    src: local('Lemon Tea'), url('Lemon Tea.woff') format('woff');
}


@font-face {
    font-family: 'Lemon Tea Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Lemon Tea Regular'), url('Lemon Tea.woff') format('woff');
    }